import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import Seo from "../components/seo";
import Layout from "../components/Layout.js";
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';


function Enhancify() {
  return (
    <Layout>
      <div className=" bg-black flex flex-col w-full h-full text-2xl justify-center pt-[70px]">
        <div className=" p-8 w-full h-fit ">
          <Link to="/">
            Go Back
          </Link>
        </div>
        <StaticImage
          src="../images/enhancify-details.jpg"
          formats={["auto", "webp", "avif"]}
          alt="enhancify details for financing options"
          className="h-full"
          layout="fullWidth"
        />
      </div>
    </Layout>

  )
}

export default Enhancify;

// export const Head = ({ location, data = {}, pageContext = {} }) => (
//   <>
//     <Helmet htmlAttributes={{ lang: "en", amp: "true" }}>
//       <Seo
//         title="Financing options offered by Enhancify "
//         description={data?.description}
//         url={`${location.pathname}`}
//       />
//     </Helmet>
//   </>
// )